import './App.css';
import {TwitchEmbed} from "react-twitch-embed";
import {TwitterTimelineEmbed} from 'react-twitter-embed';
import Feed from "react-instagram-authless-feed"
import * as React from "react";
import YouTube from "react-youtube";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-17616511-5');
ReactGA.pageview(window.location.pathname + window.location.search);

const renderHTML = (rawHTML) => React.createElement("div", {dangerouslySetInnerHTML: {__html: rawHTML}});


class LatestTiktokPost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            default: {
                "id": "6943671248870296837",
                "publishedAt": 1616699449,
                "counters": {
                    "shares": 0,
                    "comments": 2,
                    "diggs": 59,
                    "plays": 231
                },
                "thumbnailURL": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/9f1acd4a5af54d1fba310653d38a2be6_1616699580~tplv-dmt-logom:tos-maliva-p-0000/7b9989fb9c894e1b98bd3d51ba6bdd1f.image?x-expires=1618617600&x-signature=ifuZApUh82FEyPwb3Ji%2BnMPRJmc%3D",
                "text": "#200iq grenade plays 💪🤓 #Twitch #escapefromtarkov #Tarkov #fyp #streamer #bigbrain",
                "videoURL": "https://v16-web.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c001/4a8ff31580b943458a98c6992c345a8f/?a=1988&br=2040&bt=1020&cd=0%7C0%7C0&ch=0&cr=0&cs=0&dr=0&ds=3&er=&expire=1618619929&l=202104161838250101890660325F36CEA8&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=amU3dzU8dGVoNDMzODczM0ApZDVnNzs4NTs4N2Y7ZjM6PGdhbF40a3MwLWJgLS0zMTZzc2E0NTZeM2EvYDEtYi4zYDM6Yw%3D%3D&signature=0acc9ca9270d6c2068288fb7d90894da&tk=tt_webid_v2&vl=&vr=&__webid__=6945359719731446121",
                "webVideoUrl": "https://www.tiktok.com/@hayzstreams/video/6943671248870296837",
                "videoMeta": {
                    "width": 576,
                    "height": 1024,
                    "duration": 24
                },
                "user": {
                    "id": "6913676876247483397",
                    "verified": false,
                    "name": "hayzstreams",
                    "avatarURL": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/5d245323e6365742884fa2cd87ad0b3b~c5_1080x1080.jpeg?x-expires=1618682400&x-signature=mJjEgiG%2BdALSbuWQ%2FyQ2l8iUYG4%3D"
                }
            },
            data: {}
        }
    }

    async componentDidMount() {
        const query_url = "https://service-tiktok-api-291914.wl.r.appspot.com/users/hayzstreams/posts";
        const response = await fetch(query_url);
        const data = await response.json();
        this.setState({data: data.result[0]})
    }

    render() {
        return (
            <>
                {this.state.loading ? (
                    <>
                        {/*<p>{this.state.default["text"]}</p><br/>*/}
                        <a href={this.state.default["webVideoUrl"]} target="_blank" rel="noreferrer">
                            <p>{this.state.default["text"]}</p>
                            {/*<img src={this.state.default["thumbnailURL"]} alt={this.state.default["text"]}/>*/}
                        </a>
                    </>
                ) : (
                    <>
                        {/*<p>{this.state.data["text"]}</p><br/>*/}
                        <a href={this.state.data["webVideoUrl"]} target="_blank" rel="noreferrer">
                            <p>{this.state.data["text"]}</p>
                            {/*<img src={this.state.data["thumbnailURL"]} alt={this.state.data["text"]}/>*/}
                        </a>
                    </>
                )}
            </>
        )
    }
}


class LatestYoutube extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            opts: {
                width: "100%",
                height: "240px",
                playerVars: {
                    rel: 0,
                    controls: 0,
                    showinfo: 0
                }
            }
        }
    }

    async componentDidMount() {
        const youtube_query_url = "https://www.googleapis.com/youtube/v3/search?order=date&part=snippet&channelId=UCIw4aeZpgRqj9ZZlYcmVhcA&key=AIzaSyB3KrUz_gJvbFFt5ipGwMralSjanay72qw";
        const response = await fetch(youtube_query_url);
        const data = await response.json();
        this.setState({data: data.items[0], loading: false})
    }

    render() {
        return (
            <>
                {this.state.loading ? (
                    <>
                        <h2>Loading...</h2>
                        <p><small>If loading for a long time, try refreshing the page. If the issue persists, Youtube
                            might be down.</small></p>
                    </>
                ) : (
                    <>
                        <p><strong>{renderHTML(this.state.data["snippet"]["title"])}</strong></p><br/>
                        <YouTube videoId={this.state.data["id"]["videoId"]} opts={this.state.opts}
                                 onReady={this._onReady}/>
                    </>
                )}
            </>
        )
    }
}

const LatestTweet = () => {
    return (
        <div>
            <TwitterTimelineEmbed
                sourceType="profile"
                screenName="HayzStreams"
                theme="dark"
                options={{tweetLimit: "1", width: "100%", height: 400}}
                noHeader={true}
                noBorders={true}
                noFooter={true}
                noScroll={true}
                transparent={true}
                limit="1"
            />
        </div>
    )
}

function App() {
    return (
        <main>
<br/>
            <div className="grid two-col">
                <a href="https://discord.gg/hayz" className="discord-cta" target="_blank" rel="noreferrer">
                    Join <span className="highlight">Hayz</span> on Discord
                </a>

                <a href="https://korfmerch.com/collections/hayz-merchandise" className="merch-banner" target="_blank" rel="noreferrer">
                    Get some <span className="highlight">Hayz</span> Merch!<br/>
                    <small>15% off for Twitch Subs</small>
                </a>
            </div>

            <div className="twitch-player">
                <TwitchEmbed
                    channel="hayz"
                    id="hayz"
                    theme="dark"
                    width="100%"
                    muted
                />
            </div>

            <div className="grid">
                <div className="boxed">
                    <h3>UK Content Creator - Partnered with <a href="https://www.twitch.tv/" target="_blank"
                                                               rel="noreferrer">Twitch</a>
                        &nbsp;and <a href="https://sneakenergy.com/" target="_blank" rel="noreferrer">Sneak Energy</a>.
                    </h3>
                    <p>Daniel "<span className="highlight">Hayz</span>" Heaysman is a content creator from the UK and
                        has been doing so for over <span id="years">6</span> years now. He specialises in Survival FPS
                        games but loves RPG's and MMO's. Hayz was previously a signed professional PUBG player, analyst
                        and commentator. He's travelled around the world attending various tournaments.</p>
                    <p>Now, Hayz mostly plays Escape from Tarkov on Twitch and creates tips and guides on his YouTube
                        channel to help players improve their own gameplay.</p>
                </div>
            </div>

            <div className="grid three-col">
                <div className="boxed twitter">
                    <h3>Latest <a href="https://twitter.com/HayzStreams" target="_blank" rel="noreferrer">Tweet</a></h3>
                    <LatestTweet/>
                    <div className="footer">
                        <small><a href="https://twitter.com/HayzStreams" target="_blank" rel="noreferrer">View
                            Twitter</a></small>
                    </div>
                </div>
                <div className="boxed youtube">
                    <h3>Latest on <a href="https://youtube.com/user/TheIHayZ" target="_blank"
                                     rel="noreferrer">Youtube</a></h3>
                    <LatestYoutube/>
                    <div className="footer">
                        <small><a href="https://youtube.com/user/TheIHayZ" target="_blank" rel="noreferrer">View
                            Youtube</a></small>
                    </div>
                </div>
                <div className="boxed tiktok">
                    <h3>Latest on <a href="https://www.tiktok.com/@hayzstreams" target="_blank"
                                     rel="noreferrer">TikTok</a></h3>
                    <LatestTiktokPost/>
                    <div className="footer">
                        <small><a href="https://www.tiktok.com/@hayzstreams" target="_blank" rel="noreferrer">View
                            TikTok</a></small>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default App;
